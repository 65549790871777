import {
  faAirConditioner,
  faBuilding,
  faBuildings,
  faHeat,
  faHouseBuilding,
  faHouseUser,
  faTemperatureHot,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/pro-duotone-svg-icons';

import component from './GeneralStep.vue';
import i18n from '@/plugins/i18n';
import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  FormSelectValueType,
  FormSliderValueType,
  FormSwitchComponentConfig,
  FormTextFieldValueType,
  FormUploadFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormFileUploadField,
  buildFormMultipleChoiceField,
  buildFormSelect,
  buildFormSlider,
  buildFormSwitch,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';

export interface GeneralStepContext extends AssistantStepContext {
  zipCode: FormTextFieldValueType;
  constructionYear: FormSliderValueType;
  buildingType: FormMultipleChoiceValueType;
  newBuilding: FormSwitchComponentConfig;
  heatedArea: FormTextFieldValueType;
  countFloors: FormSliderValueType;
  currentBuildingHeating: FormSelectValueType;
  heatSpreader: FormMultipleChoiceValueType;
  constructionYearOfCurrentHeating: FormTextFieldValueType;
  useOfSolarThermal: FormMultipleChoiceValueType;
  alignmentRoof: FormSelectValueType;
  filesUpload: FormUploadFieldValueType;
}

const MAX_CONSTRUCTION_YEAR = new Date().getFullYear();
const MIN_CONSTRUCTION_YEAR = 1980;
const MIDDLE_CONSTRUCTION_YEAR = 2000;
const tickLabelsForConstructionYear = Array(
  MAX_CONSTRUCTION_YEAR - MIN_CONSTRUCTION_YEAR,
).fill('');
const tickLabelsI18n = 'general.formFields.constructionYear.tickLabels';
tickLabelsForConstructionYear[0] = i18n.t(`${tickLabelsI18n}.minYear`);
tickLabelsForConstructionYear[
  MIDDLE_CONSTRUCTION_YEAR - MIN_CONSTRUCTION_YEAR
] = i18n.t(`${tickLabelsI18n}.middleYear`);
tickLabelsForConstructionYear[tickLabelsForConstructionYear.length] = i18n.t(
  `${tickLabelsI18n}.maxYear`,
);

const config: StepDefinition = {
  beforeNext: (context) => {
    if (!context.general.useOfSolarThermal.includes('yes')) {
      context.general.alignmentRoof = '–';
    }

    // If we don't return true, the assistant won't go to next step.
    return true;
  },
  component,
  fields: [
    buildFormTextField('zipCode', {
      required: true,
      showDescription: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormSlider('constructionYear', {
      component: {
        max: MAX_CONSTRUCTION_YEAR,
        min: MIN_CONSTRUCTION_YEAR,
        thumbLabel: false,
        tickLabels: tickLabelsForConstructionYear,
      },
      required: true,
      showHelperDialog: true,
    }),

    buildFormSwitch('newBuilding', {
      component: {
        showLabel: true,
      },
      outputFormatter: (data) => {
        if (data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        } else {
          return {
            formattedLabel: data.formattedLabel,
            formattedValue: data.formattedValue,
          };
        }
      },
    }),

    buildFormMultipleChoiceField('buildingType', {
      component: {
        columns: 4,
        options: [
          {
            icon: faHouseUser,
            value: 'familyHouse',
          },
          {
            icon: faHouseBuilding,
            value: 'apartmentBuilding',
          },
          {
            icon: faBuilding,
            value: 'apartment',
          },
          {
            icon: faBuildings,
            value: 'other',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),

    buildFormTextField('heatedArea', {
      component: {
        min: 0,
        suffix: 'm²',
        type: 'number',
      },
      default: '20',
      required: true,
      validation: [
        {
          errorLabel: 'numericalValues.minValue',
          validator: (value) => Number(value) > 0,
        },
      ],
    }),

    buildFormSelect('currentBuildingHeating', {
      component: {
        items: ['oil', 'gas', 'electricHeating', 'heatPump', 'pellet', 'other'],
      },
      required: true,
    }),

    buildFormMultipleChoiceField('heatSpreader', {
      component: {
        columns: 4,
        options: [
          {
            icon: faHeat,
            value: 'radiators',
          },
          {
            icon: faAirConditioner,
            value: 'underfloorHeating',
          },
          {
            icon: faTemperatureHot,
            value: 'others',
          },
        ],
      },
      required: true,
    }),

    buildFormMultipleChoiceField('useOfSolarThermal', {
      component: {
        columns: 2,
        options: [
          {
            icon: faThumbsUp,
            value: 'yes',
          },
          {
            icon: faThumbsDown,
            value: 'no',
          },
        ],
        singleAnswer: true,
      },
      required: true,
    }),

    buildFormSelect('alignmentRoof', {
      component: {
        items: [
          'eastWest',
          'south',
          'west',
          'southEast',
          'east',
          'southWest',
          'north',
          'flatRoof',
          'miscellaneous',
        ],
      },
      required: true,
      outputFormatter: (data) => {
        if (data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        } else {
          return {
            formattedLabel: data.formattedLabel,
            formattedValue: data.formattedValue,
          };
        }
      },
    }),
    buildFormFileUploadField('filesUpload', {
      component: {
        accept: 'image/*,.pdf,application/pdf',
        maxFileSizeInBytes: 10 * 1024 * 1024,
        multiple: true,
      },
    }),
  ],

  hideSkipMessage: () => true,
  id: StepId.GENERAL,
};

export default config;
