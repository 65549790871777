/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Assistenten starten',
    description:
      'Sie möchten bares Geld sparen und effizienter heizen? Wir bieten Ihnen das notwendige Gesamtpaket inklusive Installation, Beratung und After-Sales-Service. Mit unserem Assistenten helfen Sie uns, Ihnen ein individuelles Angebot zu erstellen, damit Sie langfristig effizienter und nachhaltiger heizen.',
    title: 'Heizungsassistent',
    step: {
      label: 'Start',
    },
  },

  general: {
    title: 'Dateneingabe',
    step: {
      label: 'Dateneingabe',
    },
    intro: {
      text: 'Um Ihnen ein individuelles Angebot zu erstellen, benötigen wir ein paar Informationen zu Ihrem Haus und den Gegebenheiten vor Ort.',
    },
    headlines: {
      general: 'Allgemeine Angaben',
      realEstate: 'Angaben zum Objekt',
      placement: 'Montageort',
      documents: 'Bilder (optional)',
      roof: 'Angaben zur Dach',
    },
    zipCodeNotAvailable: {
      title: 'Hinweis',
      label: 'Leider können wir Ihnen in ihrer Region kein Angebot machen.',
    },
    formFields: {
      zipCode: {
        label: 'Wo wohnen Sie?',
        description: 'Bitte tragen Sie hier Ihr Postleitzahl ein',
      },
      constructionYear: {
        tickLabels: {
          minYear: 'von/vor 1980',
          middleYear: 'um 2000',
          maxYear: 'bis heute',
        },

        label: 'Aus welchem Baujahr ist das Gebäude?',
        helperDialog: {
          text: 'Alternativ geben Sie bitte das Jahr der letzten energetischen Sanierung an.',
        },
        options: {
          '1980': {
            label: 'von / vor 1980',
          },
          '2000': {
            label: 'um 2000',
          },
          today: {
            label: 'bis heute',
          },
        },
      },
      newBuilding: {
        label: 'Bei Ihrem Gebäude handelt es sich um einen …',
        component: {
          label: 'Neubau',
        },
      },
      buildingType: {
        label: 'Um was für ein Gebäude handelt es sich?',
        options: {
          familyHouse: {
            label: 'Einfamilienhaus',
          },
          apartmentBuilding: {
            label: 'Mehrfamilienhaus',
          },
          apartment: {
            label: 'Etagenwohnung',
          },
          other: {
            label: 'Sonstiges',
          },
        },
      },
      heatSpreader: {
        label: 'Welchen Wärmeverteiler haben Sie?',
        options: {
          radiators: {
            label: 'Heizkörper',
          },
          underfloorHeating: {
            label: 'Fußbodenheizung',
          },
          others: {
            label: 'Sonstige',
          },
        },
      },
      heatedArea: {
        label: 'Geben Sie die zu beheizende Fläche an',
      },
      currentBuildingHeating: {
        label: 'Wie wird das Gebäude derzeit beheizt?',
        options: {
          oil: {
            label: 'Öl',
          },
          gas: {
            label: 'Gas',
          },
          electricHeating: {
            label: 'Elektroheizung',
          },
          heatPump: {
            label: 'Wärmepumpe',
          },
          pellet: {
            label: 'Pellet',
          },
          other: {
            label: 'Sonstiges',
          },
        },
      },
      constructionYearOfCurrentHeating: {
        label: 'Welches Baujahr hat die derzeitige Heizung?',
      },
      useOfSolarThermal: {
        label: 'Ist der Einsatz von Solarthermie für Sie relevant?',
        options: {
          yes: {
            label: 'Ja',
          },
          no: {
            label: 'Nein',
          },
        },
      },
      alignmentRoof: {
        label: 'In welche Richtung ist Ihr Dach ausgerichtet?',
        options: {
          east: {
            label: 'Ost',
          },
          south: {
            label: 'Süd',
          },
          west: {
            label: 'West',
          },
          eastWest: {
            label: 'Ost-West',
          },
          southEast: {
            label: 'Süd-Ost',
          },
          southWest: {
            label: 'Süd-West',
          },
          north: {
            label: 'Nord',
          },
          flatRoof: {
            label: 'Flachdach',
          },
          miscellaneous: {
            label: 'Sonstiges',
          },
        },
      },
      filesUpload: {
        label: 'Fotos/Dokumente hochladen',
      },
    },
  },
  documents: {
    title: 'Weiterführende Angaben zu Ihrer Situation',
    step: {
      label: 'Dokumente',
    },
    formFields: {
      filesUpload: {
        label: 'Fotos/Dokumente hochladen',
      },
    },
    info:
      '**Optional können Sie folgende Fotos/Dokumente hochladen:**\n' +
      '- Heizkessel/Therme von vorne und von der Seite\n' +
      '- Warmwasserspeicher\n' +
      '- Typenschild des Kessels und Warmwasserspeichers\n' +
      '- Letzter Bericht des Schornsteinfegers\n' +
      '- Letzter Jahresverbrauch\n',
  },
  appointment: {
    title: 'Gerne beraten wir Sie telefonisch',
    step: {
      label: 'Terminvereinbarung',
    },
    formFields: {
      appointment: {
        label:
          'In welchen Zeitfenstern dürfen wir nach einem Termin für Sie suchen?',
        options: {
          tuesdays1415: {
            label: 'Dienstags 14:00-15:00 Uhr',
          },
          tuesdays1516: {
            label: 'Dienstags 15:00-16:00 Uhr',
          },
          thursdays1415: {
            label: 'Donnerstags 14:00-15:00 Uhr',
          },
          thursdays1516: {
            label: 'Donnerstags 15:00-16:00 Uhr',
          },
        },
      },
    },
  },
  contactForm: {
    formFields: {
      phone: {
        label: 'Mobil-/Telefonnummer',
      },
      customerNumber: {
        label: 'Kundennummer',
        helperDialog: {
          text: 'Ihre Vertragskontennummer (12-stellig) finden  Sie auf Ihrer Energielieferrechnung)',
        },
      },
      privacyConfirmation: {
        summaryHeadline: 'Ich stimme der Datenschutzerklärung zu',
        headline:
          'Hiermit stimme ich der <a href="https://www.sw-kassel.de/privatkunden/datenschutz/" target="_blank">Datenschutzerklärung</a> zu.',
      },
      newsletterConfirmation: {
        helperDialog: {
          text: 'Angebote und individuelle Vorteile: z.B. zu Strom- und Gasprodukten, Fernwärme, Energiedienstleistungen, ÖPNV, Telekommunikation und den Bäder der Städtische Werke AG sowie der ihr verbundenen Unternehmen der Kasseler Verkehrs- und Versorgungs-GmbH. Je nach Thema und Wichtigkeit informieren wir Sie per Brief, E-Mail oder telefonisch. Diesen praktischen Infoservice können Sie jederzeit widerrufen.',
        },
      },
    },
  },
  message: {
    error: 'productRequest data send error',
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
