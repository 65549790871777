import general, { GeneralStepContext } from '~/steps/general';
import appointment, { AppointmentStepContext } from '~/steps/appointment';
import contactForm, { ContactFormStepContext } from '~/steps/contact-form';
import summary from '~/steps/summary';

import done from '~/steps/done';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [general, appointment, contactForm, summary, done];

// Add the imported context interface for the steps
export interface AssistantContext {
  general: GeneralStepContext;
  appointment: AppointmentStepContext;
  contactForm: ContactFormStepContext;
}

export default steps;
