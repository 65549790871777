import { StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormMultipleChoiceField } from '@/lib/forms/builder';

export interface AppointmentStepContext extends AssistantStepContext {
  heatingUsage: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  fields: [
    buildFormMultipleChoiceField('appointment', {
      component: {
        columns: 2,
        options: [
          {
            value: 'tuesdays1415',
          },
          {
            value: 'tuesdays1516',
          },
          {
            value: 'thursdays1415',
          },
          {
            value: 'thursdays1516',
          },
        ],
      },
    }),
  ],

  id: StepId.APPOINTMENT,
};

export default config;
