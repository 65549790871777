import MarkdownIt from 'markdown-it';
import { matomoTrackEvent } from '@/lib/analytics/matomo';
import { getProjectRequestDataForComment } from '@/lib/formatter/product-request';
import { sendProductRequest } from '@/lib/submit/send-product-request';
import { StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';
import { AssistantContext } from '~/steps/steps';
import { getFilesFromContext } from '@/lib/xstate/machine';
import { uploadFiles } from '@/lib/submit/upload-files';
import { getMailTemplate } from '@/lib/submit/helper';
import { getMarkdownData } from '@/lib/formatter/context';
import { getProductIdFromUrl, getProductRequest } from '~/steps/summary/helper';
import { loadProductsByIds } from '@/lib/greenportal/load-products';
import { sendMail } from '@/lib/submit/send-mail';
import { sendEvent } from '@/lib/analytics/gtag';

const DEFAULT_SUBJECT = 'Anfrage vom Assistenten';

const md = new MarkdownIt({
  breaks: true,
  html: true,
});

const config: StepDefinition = {
  hideSkipMessage: () => true,
  id: StepId.SUMMARY,

  submit: async ({ i18n, steps, assistantContext }): Promise<void> => {
    try {
      const contextFiles = getFilesFromContext(assistantContext);
      const s3UploadedFilePaths = await uploadFiles(contextFiles);

      // send product request
      const commentsData = getProjectRequestDataForComment(
        steps as StepDefinition[],
        assistantContext as AssistantContext,
      );

      const productId = getProductIdFromUrl();

      const productRequest = getProductRequest(
        assistantContext,
        commentsData,
        productId,
      );

      // Get the name of the product to track it with matomo
      const productDetails = await loadProductsByIds([productId]);

      const productName =
        productDetails.length === 1 &&
        productDetails[0].name !== null &&
        productDetails[0].name.length > 0
          ? productDetails[0].name
          : '';

      if (productName.length === 0) {
        // eslint-disable-next-line no-console
        console.warn(
          `Couldn't get name of product with id ${productId}. Will track matomo event with the product url instead.`,
        );
      }

      const productNumber =
        productDetails.length === 1 &&
        productDetails[0].productNumber !== null &&
        productDetails[0].productNumber.length > 0
          ? productDetails[0].productNumber
          : '';

      await sendProductRequest({
        productRequest,
        shopHost: process.env.VUE_APP_SHOP_HOST,
        shopApiKey: process.env.VUE_APP_SHOP_API_KEY,
        shopUseBasicAuth: process.env.VUE_APP_SHOP_USE_BASIC_AUTH === 'true',
        shopBasicAuth:
          process.env.VUE_APP_SHOP_USE_BASIC_AUTH === 'true'
            ? process.env.VUE_APP_SHOP_BASIC_AUTH
            : undefined,
        disableCustomerMail:
          process.env.VUE_APP_SHOP_DISABLE_CUSTOMER_MAIL === 'true',
        s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
        s3FileAttachmentPaths: s3UploadedFilePaths,
      });

      const mjmlTemplate = await getMailTemplate();

      const formattedProductInfo = `## Produkt \n\n **Name:** ${productName} \n\n **Artikelnummer:** ${productNumber} \n\n`;

      const markdownFormattedContext = getMarkdownData(steps, assistantContext);

      const renderedMarkdownContext = md.render(
        formattedProductInfo + markdownFormattedContext,
      );

      const mailBody = mjmlTemplate
        .replace('{{subject}}', DEFAULT_SUBJECT)
        .replace('{{content}}', renderedMarkdownContext);

      await sendMail({
        mailSubject: DEFAULT_SUBJECT,
        mailBody,
        mailFromAddress: process.env.VUE_APP_MAIL_FROM,
        mailToAddress: process.env.VUE_APP_MAIL_RECIPIENT,
        mailBccAddress: process.env.VUE_APP_MAIL_BCC,
        s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
        s3FileAttachmentPaths: s3UploadedFilePaths,
      });

      matomoTrackEvent(
        'Shop',
        'Anfrage',
        productName.length > 0 ? productName : productRequest.productUrl,
      );

      sendEvent('conversion', { send_to: 'AW-866404130/h-yqCKLC03MQoo6RnQM' });
    } catch (error) {
      let errorMessage = i18n.t('message.error').toString();
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      // eslint-disable-next-line no-console
      console.error(errorMessage);

      throw new Error(errorMessage);
    }
  },
  type: StepTypes.SUMMARY,
};

export default config;
