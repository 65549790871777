/* eslint-disable no-console */
export const sendEvent = (
  event: string,
  params: { [index: string]: string | number },
) => {
  if (typeof gtag === 'undefined') {
    console.warn('Google Tag Manager is not defined');
    return;
  }

  gtag('event', event, params);
};
/* eslint-enable no-console */
